import React from "react";

const ResumeSection = () => {
  return (
    <>
      <section className="" id="resume">
        <div className="max-w-7xl mx-auto px-4">
          <div className="py-10">
            <div className="">
              <div className="text-center">
                <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-black md:text-5xl lg:text-[50px]">
                  My Résumé
                </h1>
                <p className="pb-8 text-[33px] capitalize font-medium text-[#8D8D8D] sm:px-16 xl:px-48 ">
                  Get to Know Where I Have Proven My Skills
                </p>
              </div>
              <div className="px-8">
                <div className="flex space-x-8">
                  <div className="w-1/4">
                    <p className="uppercase text-2xl font-extrabold">
                      Work Experience
                    </p>
                  </div>
                  <div className="w-3/4">
                    <div className="">
                      <div className="flex justify-between items-center">
                        <p className="text-lg font-extrabold uppercase">
                          Digital Project manager
                        </p>
                        <p className="flex items-center space-x-4">
                          <span className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M18.6806 5.0625H5.31944C3.90038 5.0625 2.75 6.21288 2.75 7.63194V18.4236C2.75 19.8427 3.90038 20.9931 5.31944 20.9931H18.6806C20.0996 20.9931 21.25 19.8427 21.25 18.4236V7.63194C21.25 6.21288 20.0996 5.0625 18.6806 5.0625Z"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.75 10.2012H21.25"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17.1389 3.00684V7.11795"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6.86108 3.00684V7.11795"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.375 14.0557H8.40278"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.5972 14.0557H16.625"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.4861 14.0557H12.5139"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.375 17.1387H8.40278"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.5972 17.1387H16.625"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.4861 17.1387H12.5139"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-[#8D8D8D]">
                            July 2020 - Present
                          </span>
                        </p>
                      </div>
                      <div className="py-5">
                        <p className="flex items-center space-x-4">
                          <span className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_12_492)">
                                <path
                                  d="M4.19836 21.5867C4.67828 21.5867 5.1582 21.5867 5.65996 21.5867C5.65996 21.4781 5.65996 21.3891 5.65996 21.3C5.65996 16.745 5.65996 12.1899 5.65996 7.63546C5.65996 7.05642 5.7938 6.88394 6.35044 6.73442C9.10452 5.99354 11.862 5.26442 14.6099 4.49946C15.2063 4.3337 15.6772 4.6921 15.6487 5.30026C15.6134 6.05178 15.6403 6.80666 15.6403 7.57442C15.1822 7.57442 14.7381 7.57442 14.2722 7.57442C14.2722 7.07434 14.2722 6.5681 14.2722 6.03106C13.9653 6.11114 13.6859 6.18226 13.4076 6.2573C11.3468 6.8117 9.28708 7.36834 7.22516 7.9177C7.07564 7.95746 7.03196 8.02018 7.03196 8.1725C7.03644 12.5735 7.03532 16.9751 7.03532 21.3762C7.03532 21.4378 7.03532 21.4999 7.03532 21.5755C9.44388 21.5755 11.8368 21.5755 14.261 21.5755C14.261 21.491 14.261 21.4036 14.261 21.3163C14.261 17.4808 14.261 13.6454 14.261 9.8105C14.261 9.21858 14.5096 8.96994 15.1004 8.96938C17.8808 8.9677 20.6612 8.96546 23.4416 8.96434C23.9221 8.96434 24.1976 9.23482 24.1982 9.71474C24.1993 12.3831 24.1988 15.0521 24.1982 17.7205C24.1982 17.7821 24.192 17.8431 24.1887 17.9159C23.7351 17.9159 23.2927 17.9159 22.8273 17.9159C22.8273 15.3915 22.8273 12.8804 22.8273 10.3537C20.426 10.3537 18.0477 10.3537 15.6548 10.3537C15.6548 14.0934 15.6548 17.8269 15.6548 21.5845C18.9807 21.5845 22.3082 21.5845 25.648 21.5845C25.648 22.0521 25.648 22.4962 25.648 22.9699C18.4991 22.9699 11.3484 22.9699 4.19836 22.9699C4.19836 22.5091 4.19836 22.0482 4.19836 21.5867Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M12.2814 12.1328C12.2814 12.5926 12.2814 13.0305 12.2814 13.4835C11.1989 13.4835 10.1226 13.4835 9.02893 13.4835C9.02893 13.0406 9.02893 12.5931 9.02893 12.1328C10.1092 12.1328 11.1855 12.1328 12.2814 12.1328Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M20.8348 13.4921C19.7439 13.4921 18.6721 13.4921 17.5857 13.4921C17.5857 13.039 17.5857 12.591 17.5857 12.1318C18.6699 12.1318 19.7462 12.1318 20.8348 12.1318C20.8348 12.586 20.8348 13.0284 20.8348 13.4921Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M12.2702 16.6274C11.1838 16.6274 10.1164 16.6274 9.03003 16.6274C9.03003 16.1738 9.03003 15.7258 9.03003 15.2666C10.1058 15.2666 11.1793 15.2666 12.2702 15.2666C12.2702 15.7112 12.2702 16.1598 12.2702 16.6274Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M20.8332 16.6236C19.7423 16.6236 18.6693 16.6236 17.5835 16.6236C17.5835 16.1706 17.5835 15.7271 17.5835 15.2695C18.6632 15.2695 19.7412 15.2695 20.8332 15.2695C20.8332 15.7159 20.8332 16.1594 20.8332 16.6236Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M9.021 10.3511C9.021 9.90198 9.021 9.46574 9.021 9.0127C10.1035 9.0127 11.1798 9.0127 12.2718 9.0127C12.2718 9.45062 12.2718 9.89302 12.2718 10.3511C11.1966 10.3511 10.1203 10.3511 9.021 10.3511Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M9.02893 18.396C10.1181 18.396 11.19 18.396 12.2747 18.396C12.2747 18.8474 12.2747 19.2898 12.2747 19.745C11.1911 19.745 10.1153 19.745 9.02893 19.745C9.02893 19.2914 9.02893 18.8446 9.02893 18.396Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M20.8293 19.7489C19.7401 19.7489 18.6682 19.7489 17.5835 19.7489C17.5835 19.2976 17.5835 18.8552 17.5835 18.3999C18.6665 18.3999 19.7429 18.3999 20.8293 18.3999C20.8293 18.8529 20.8293 19.2998 20.8293 19.7489Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M22.8407 19.1694C23.2915 19.1694 23.7289 19.1694 24.1825 19.1694C24.1825 19.5894 24.1825 20.0033 24.1825 20.4339C23.744 20.4339 23.301 20.4339 22.8407 20.4339C22.8407 20.0229 22.8407 19.609 22.8407 19.1694Z"
                                  fill="#8D8D8D"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_12_492">
                                  <rect width="28" height="28" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="text-lg font-extrabold text-[#8D8D8D]">
                            Mavengers Limited
                          </span>
                        </p>

                        <div className="py-1">
                          <p className="flex items-center space-x-4 ml-2">
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              1.
                            </span>
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              Project Planning and Strategy:
                            </span>
                          </p>
                          <ul className="list-disc list-outside ml-16 text-base font-normal text-[#8D8D8D]">
                            <li className="">
                              Define project objectives, scope, and
                              deliverables.
                            </li>
                            <li className="">
                              Develop comprehensive project plans, including
                              timelines, tasks, resource allocation, and
                              budgets.
                            </li>
                            <li className="">
                              Create strategies for achieving project goals,
                              taking into account the digital nature of the
                              projects.
                            </li>
                          </ul>
                        </div>
                        <div className="py-1">
                          <p className="flex items-center space-x-4 ml-2">
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              2.
                            </span>
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              Team Coordination and Collaboration:
                            </span>
                          </p>
                          <ul className="list-disc list-outside ml-16 text-base font-normal text-[#8D8D8D]">
                            <li className="">
                              Assemble cross-functional teams, assign roles and
                              responsibilities, and promote effective
                              collaboration.
                            </li>
                            <li className="">
                              Facilitate communication and teamwork among team
                              members and stakeholders.
                            </li>
                          </ul>
                        </div>
                        <div className="py-1">
                          <p className="flex items-center space-x-4 ml-2">
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              3.
                            </span>
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              Technology and Tools:
                            </span>
                          </p>
                          <ul className="list-disc list-outside ml-16 text-base font-normal text-[#8D8D8D]">
                            <li className="">
                              Utilize project management software and digital
                              tools to manage tasks, track progress, and
                              communicate with team members and stakeholders.
                            </li>
                            <li className="">
                              Implement Agile or other relevant methodologies
                              for digital project management.
                            </li>
                          </ul>
                        </div>
                        <div className="py-1">
                          <p className="flex items-center space-x-4 ml-2">
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              4.
                            </span>
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              Timeline and Budget Management:
                            </span>
                          </p>
                          <ul className="list-disc list-outside ml-16 text-base font-normal text-[#8D8D8D]">
                            <li className="">
                              Monitor project timelines and budgets closely,
                              making adjustments as needed to address delays or
                              changes in scope.
                            </li>
                            <li className="">
                              Ensure projects are completed within budgetary
                              constraints.
                            </li>
                          </ul>
                        </div>
                        <div className="py-1">
                          <p className="flex items-center space-x-4 ml-2">
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              5.
                            </span>
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              Quality Assurance:
                            </span>
                          </p>
                          <ul className="list-disc list-outside ml-16 text-base font-normal text-[#8D8D8D]">
                            <li className="">
                              Implement quality control processes to ensure that
                              digital deliverables meet established standards
                              and specifications.
                            </li>
                            <li className="">
                              Conduct testing and reviews to verify the quality
                              of digital products or services.
                            </li>
                          </ul>
                        </div>
                        <div className="py-1">
                          <p className="flex items-center space-x-4 ml-2">
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              5.
                            </span>
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              Risk Management:
                            </span>
                          </p>
                          <ul className="list-disc list-outside ml-16 text-base font-normal text-[#8D8D8D]">
                            <li className="">
                              Identify potential risks that could impact project
                              success and develop mitigation strategies.
                            </li>
                            <li className="">
                              Address technical challenges and unexpected issues
                              that may arise during the project lifecycle.
                            </li>
                          </ul>
                        </div>
                        <div className="py-1">
                          <p className="flex items-center space-x-4 ml-2">
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              5.
                            </span>
                            <span className="text-lg font-normal text-[#8D8D8D]">
                              Stakeholder Communication:
                            </span>
                          </p>
                          <ul className="list-disc list-outside ml-16 text-base font-normal text-[#8D8D8D]">
                            <li className="">
                              Establish clear communication channels with
                              project stakeholders.
                            </li>
                            <li className="">
                              Provide regular project updates, conduct status
                              meetings, and report on project progress.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex justify-between items-center pt-5">
                        <p className="text-lg font-extrabold uppercase">
                          Project manager
                        </p>
                        <p className="flex items-center space-x-4">
                          <span className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M18.6806 5.0625H5.31944C3.90038 5.0625 2.75 6.21288 2.75 7.63194V18.4236C2.75 19.8427 3.90038 20.9931 5.31944 20.9931H18.6806C20.0996 20.9931 21.25 19.8427 21.25 18.4236V7.63194C21.25 6.21288 20.0996 5.0625 18.6806 5.0625Z"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.75 10.2012H21.25"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17.1389 3.00684V7.11795"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6.86108 3.00684V7.11795"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.375 14.0557H8.40278"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.5972 14.0557H16.625"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.4861 14.0557H12.5139"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.375 17.1387H8.40278"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.5972 17.1387H16.625"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.4861 17.1387H12.5139"
                                stroke="#8D8D8D"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="text-[#8D8D8D]">
                            June 2014 - June 2020
                          </span>
                        </p>
                      </div>
                      <div className="py-5">
                        <p className="flex items-center space-x-4">
                          <span className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_12_492)">
                                <path
                                  d="M4.19836 21.5867C4.67828 21.5867 5.1582 21.5867 5.65996 21.5867C5.65996 21.4781 5.65996 21.3891 5.65996 21.3C5.65996 16.745 5.65996 12.1899 5.65996 7.63546C5.65996 7.05642 5.7938 6.88394 6.35044 6.73442C9.10452 5.99354 11.862 5.26442 14.6099 4.49946C15.2063 4.3337 15.6772 4.6921 15.6487 5.30026C15.6134 6.05178 15.6403 6.80666 15.6403 7.57442C15.1822 7.57442 14.7381 7.57442 14.2722 7.57442C14.2722 7.07434 14.2722 6.5681 14.2722 6.03106C13.9653 6.11114 13.6859 6.18226 13.4076 6.2573C11.3468 6.8117 9.28708 7.36834 7.22516 7.9177C7.07564 7.95746 7.03196 8.02018 7.03196 8.1725C7.03644 12.5735 7.03532 16.9751 7.03532 21.3762C7.03532 21.4378 7.03532 21.4999 7.03532 21.5755C9.44388 21.5755 11.8368 21.5755 14.261 21.5755C14.261 21.491 14.261 21.4036 14.261 21.3163C14.261 17.4808 14.261 13.6454 14.261 9.8105C14.261 9.21858 14.5096 8.96994 15.1004 8.96938C17.8808 8.9677 20.6612 8.96546 23.4416 8.96434C23.9221 8.96434 24.1976 9.23482 24.1982 9.71474C24.1993 12.3831 24.1988 15.0521 24.1982 17.7205C24.1982 17.7821 24.192 17.8431 24.1887 17.9159C23.7351 17.9159 23.2927 17.9159 22.8273 17.9159C22.8273 15.3915 22.8273 12.8804 22.8273 10.3537C20.426 10.3537 18.0477 10.3537 15.6548 10.3537C15.6548 14.0934 15.6548 17.8269 15.6548 21.5845C18.9807 21.5845 22.3082 21.5845 25.648 21.5845C25.648 22.0521 25.648 22.4962 25.648 22.9699C18.4991 22.9699 11.3484 22.9699 4.19836 22.9699C4.19836 22.5091 4.19836 22.0482 4.19836 21.5867Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M12.2814 12.1328C12.2814 12.5926 12.2814 13.0305 12.2814 13.4835C11.1989 13.4835 10.1226 13.4835 9.02893 13.4835C9.02893 13.0406 9.02893 12.5931 9.02893 12.1328C10.1092 12.1328 11.1855 12.1328 12.2814 12.1328Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M20.8348 13.4921C19.7439 13.4921 18.6721 13.4921 17.5857 13.4921C17.5857 13.039 17.5857 12.591 17.5857 12.1318C18.6699 12.1318 19.7462 12.1318 20.8348 12.1318C20.8348 12.586 20.8348 13.0284 20.8348 13.4921Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M12.2702 16.6274C11.1838 16.6274 10.1164 16.6274 9.03003 16.6274C9.03003 16.1738 9.03003 15.7258 9.03003 15.2666C10.1058 15.2666 11.1793 15.2666 12.2702 15.2666C12.2702 15.7112 12.2702 16.1598 12.2702 16.6274Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M20.8332 16.6236C19.7423 16.6236 18.6693 16.6236 17.5835 16.6236C17.5835 16.1706 17.5835 15.7271 17.5835 15.2695C18.6632 15.2695 19.7412 15.2695 20.8332 15.2695C20.8332 15.7159 20.8332 16.1594 20.8332 16.6236Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M9.021 10.3511C9.021 9.90198 9.021 9.46574 9.021 9.0127C10.1035 9.0127 11.1798 9.0127 12.2718 9.0127C12.2718 9.45062 12.2718 9.89302 12.2718 10.3511C11.1966 10.3511 10.1203 10.3511 9.021 10.3511Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M9.02893 18.396C10.1181 18.396 11.19 18.396 12.2747 18.396C12.2747 18.8474 12.2747 19.2898 12.2747 19.745C11.1911 19.745 10.1153 19.745 9.02893 19.745C9.02893 19.2914 9.02893 18.8446 9.02893 18.396Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M20.8293 19.7489C19.7401 19.7489 18.6682 19.7489 17.5835 19.7489C17.5835 19.2976 17.5835 18.8552 17.5835 18.3999C18.6665 18.3999 19.7429 18.3999 20.8293 18.3999C20.8293 18.8529 20.8293 19.2998 20.8293 19.7489Z"
                                  fill="#8D8D8D"
                                />
                                <path
                                  d="M22.8407 19.1694C23.2915 19.1694 23.7289 19.1694 24.1825 19.1694C24.1825 19.5894 24.1825 20.0033 24.1825 20.4339C23.744 20.4339 23.301 20.4339 22.8407 20.4339C22.8407 20.0229 22.8407 19.609 22.8407 19.1694Z"
                                  fill="#8D8D8D"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_12_492">
                                  <rect width="28" height="28" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="text-lg font-extrabold text-[#8D8D8D]">
                            Aspire Next
                          </span>
                        </p>

                        <div className="py-4">
                          <ul className="list-decimal list-outside ml-6 text-[#8D8D8D]">
                            <li className="">
                              Project Planning: Develop comprehensive project
                              plans, including objectives, scope, timelines,
                              budgets, and resource allocation. Define project
                              tasks and deliverables.
                            </li>
                            <li className="">
                              Team Leadership: Assemble project teams, assign
                              roles and responsibilities, and provide guidance
                              to team members throughout the project lifecycle.
                              Foster collaboration and effective communication
                            </li>
                            <li className="">
                              Budget Management: Monitor project budgets, track
                              expenses, and ensure cost control. Identify and
                              address budget variances.
                            </li>
                            <li className="">
                              Timeline Management: Create and maintain project
                              schedules. Monitor progress and take corrective
                              actions to ensure projects stay on track and meet
                              deadlines.
                            </li>
                            <li className="">
                              Risk Management: Identify project risks and
                              develop mitigation strategies. Proactively address
                              issues and changes that may impact project scope
                              or timeline.
                            </li>
                            <li className="">
                              Stakeholder Communication: Establish clear
                              communication channels with project stakeholders,
                              including regular status updates, meetings, and
                              reporting. Ensure alignment with project goals.
                            </li>
                            <li className="">
                              Quality Assurance: Oversee quality control
                              processes to ensure project deliverables meet
                              predefined quality standards.
                            </li>
                            <li className="">
                              Resource Allocation: Allocate and manage project
                              resources efficiently, including personnel,
                              materials, and equipment.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-8 pt-10">
                  <div className="w-1/4">
                    <p className="uppercase text-2xl font-extrabold">
                      Education
                    </p>
                  </div>
                  <div className="w-3/4">
                    <div className="w-full border-b border-[#8D8D8D] pb-5">
                      <div className="">
                        <div className="flex justify-between items-center">
                          <p className="text-lg font-extrabold uppercase w-3/5">
                            Masters in business administration
                          </p>
                          <p className="flex items-center space-x-4 w-2/5">
                            <span className="">
                              <img
                                src="/assets/calender.svg"
                                alt=""
                                className=""
                              />
                            </span>
                            <span className="text-[#8D8D8D]">
                              Post Graduation on April 2016
                            </span>
                          </p>
                        </div>
                        <div className="py-5">
                          <div className="flex items-center justify-between">
                            <p className="flex items-center space-x-4 w-3/5">
                              <span className="">
                                <img
                                  src="/assets/university.svg"
                                  alt=""
                                  className=""
                                />
                              </span>
                              <span className="text-lg font-extrabold text-[#8D8D8D]">
                                Ahsanullah University of Science & Technology
                              </span>
                            </p>
                            <p className="flex items-center space-x-4 w-2/5">
                              <span className="text-[#8D8D8D]">Major:</span>
                              <span className="text-[#8D8D8D]">Accounting</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border-b border-[#8D8D8D] py-5">
                      <div className="">
                        <div className="flex justify-between items-center">
                          <p className="text-lg font-extrabold uppercase w-3/5">
                            Bachelor in business administration
                          </p>
                          <p className="flex items-center space-x-4 w-2/5">
                            <span className="">
                              <img
                                src="/assets/calender.svg"
                                alt=""
                                className=""
                              />
                            </span>
                            <span className="text-[#8D8D8D]">
                              Graduation on April 2014
                            </span>
                          </p>
                        </div>
                        <div className="py-5">
                          <div className="flex items-center justify-between">
                            <p className="flex items-center space-x-4 w-3/5">
                              <span className="">
                                <img
                                  src="/assets/university.svg"
                                  alt=""
                                  className=""
                                />
                              </span>
                              <span className="text-lg font-extrabold text-[#8D8D8D]">
                                Ahsanullah University of Science & Technology
                              </span>
                            </p>
                            <p className="flex items-center space-x-4 w-2/5">
                              <span className="text-[#8D8D8D]">Major:</span>
                              <span className="text-[#8D8D8D]">Accounting</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border-b border-[#8D8D8D] py-5">
                      <div className="">
                        <div className="flex justify-between items-center">
                          <p className="text-lg font-extrabold uppercase w-3/5">
                            higher secondary school certificate
                          </p>
                          <p className="flex items-center space-x-4 w-2/5">
                            <span className="">
                              <img
                                src="/assets/calender.svg"
                                alt=""
                                className=""
                              />
                            </span>
                            <span className="text-[#8D8D8D]">
                              Passing Year 2008
                            </span>
                          </p>
                        </div>
                        <div className="py-5">
                          <div className="flex items-center justify-between">
                            <p className="flex items-center space-x-4 w-3/5">
                              <span className="">
                                <img
                                  src="/assets/university.svg"
                                  alt=""
                                  className=""
                                />
                              </span>
                              <span className="text-lg font-extrabold text-[#8D8D8D]">
                                Cumilla Victoria College
                              </span>
                            </p>
                            <p className="flex items-center space-x-4 w-2/5">
                              <span className="text-[#8D8D8D]">Group:</span>
                              <span className="text-[#8D8D8D]">
                                Business Studies
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border-b border-[#8D8D8D] py-5">
                      <div className="">
                        <div className="flex justify-between items-center">
                          <p className="text-lg font-extrabold uppercase w-3/5">
                            secondary school certificate
                          </p>
                          <p className="flex items-center space-x-4 w-2/5">
                            <span className="">
                              <img
                                src="/assets/calender.svg"
                                alt=""
                                className=""
                              />
                            </span>
                            <span className="text-[#8D8D8D]">
                              Passing Year 2006
                            </span>
                          </p>
                        </div>
                        <div className="py-5">
                          <div className="flex items-center justify-between">
                            <p className="flex items-center space-x-4 w-3/5">
                              <span className="">
                                <img
                                  src="/assets/university.svg"
                                  alt=""
                                  className=""
                                />
                              </span>
                              <span className="text-lg font-extrabold text-[#8D8D8D]">
                                Cumilla Zilla School
                              </span>
                            </p>
                            <p className="flex items-center space-x-4 w-2/5">
                              <span className="text-[#8D8D8D]">Group:</span>
                              <span className="text-[#8D8D8D]">
                                Business Studies
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-8 pt-10">
                  <div className="w-1/4">
                    <p className="uppercase text-2xl font-extrabold">
                      Training & Certification
                    </p>
                  </div>
                  <div className="w-3/4">
                    <div className="w-full border-b border-[#8D8D8D] pb-5">
                      <div className="">
                        <div className="flex justify-between">
                          <p className="text-lg font-extrabold uppercase w-3/5">
                            Project management principles and practices
                            specialisation
                          </p>
                          <p className="flex space-x-4 w-2/5">
                            <span className="">
                              <img
                                src="/assets/calender.svg"
                                alt=""
                                className=""
                              />
                            </span>
                            <span className="text-[#8D8D8D]">
                              Issued on December 2014
                            </span>
                          </p>
                        </div>
                        <div className="pt-5">
                          <div className="flex items-center justify-between">
                            <p className="flex items-center space-x-4 w-3/5">
                              <span className="">
                                <img
                                  src="/assets/courersa.png"
                                  alt=""
                                  className=""
                                />
                              </span>
                              <span className="text-lg font-extrabold text-[#8D8D8D]">
                                Coursera
                              </span>
                            </p>
                          </div>
                          <div className="flex">
                            <div className="w-3/4">
                              <div className="py-4">
                                <p className="text-lg font-extrabold text-[#8D8D8D]">
                                  What I’ve learned
                                </p>
                                <ul className="">
                                  <li className="flex items-center space-x-2 space-y-1">
                                    <span className="">
                                      <img
                                        src="/assets/tick.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                    <span className="">
                                      Define a project’s scope and write a
                                      project plan
                                    </span>
                                  </li>
                                  <li className="flex items-center space-x-2 space-y-1">
                                    <span className="">
                                      <img
                                        src="/assets/tick.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                    <span className="">
                                      Create a project budget
                                    </span>
                                  </li>
                                  <li className="flex items-center space-x-2 space-y-1">
                                    <span className="">
                                      <img
                                        src="/assets/tick.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                    <span className="">
                                      Build a work breakdown schedule
                                    </span>
                                  </li>
                                  <li className="flex items-center space-x-2 space-y-1">
                                    <span className="">
                                      <img
                                        src="/assets/tick.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                    <span className="">
                                      Identify and manage risks
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="w-1/2">
                              <div className="py-4">
                                <p className="text-lg font-extrabold text-[#8D8D8D]">
                                  What I’ve learned
                                </p>
                                <div className="flex flex-wrap py-4">
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded  mb-2">
                                    Schedule
                                  </p>
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded  mb-2">
                                    Project Management
                                  </p>
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded  mb-2">
                                    Budget
                                  </p>{" "}
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded  mb-2">
                                    Risk management
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border-b border-[#8D8D8D] py-5">
                      <div className="">
                        <div className="flex justify-between">
                          <p className="text-lg font-extrabold uppercase w-3/5">
                            Foundations of Project Management
                          </p>
                          <p className="flex space-x-4 w-2/5">
                            <span className="">
                              <img
                                src="/assets/calender.svg"
                                alt=""
                                className=""
                              />
                            </span>
                            <span className="text-[#8D8D8D]">
                              Issued on December 2014
                            </span>
                          </p>
                        </div>
                        <div className="pt-5">
                          <div className="flex items-center justify-between">
                            <p className="flex items-center space-x-4 w-3/5">
                              <span className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={26}
                                  height={26}
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <path
                                    d="M16.4729 19.1741C17.5705 18.4997 18.4762 17.6269 19.1374 16.5162C19.3292 16.1988 19.4812 15.8616 19.6333 15.5244C19.7126 15.3459 19.673 15.2996 19.4812 15.3063C18.3771 15.3129 17.2729 15.3063 16.1688 15.3063C15.1704 15.3063 14.1721 15.2996 13.1803 15.3129C12.9952 15.3129 12.9423 15.26 12.9489 15.0748C12.9555 13.5608 12.9555 12.0401 12.9489 10.526C12.9489 10.3607 12.9886 10.2946 13.1671 10.3012C17.0812 10.3078 20.9953 10.3078 24.9094 10.3012C25.0615 10.3012 25.121 10.3409 25.1474 10.4996C25.2069 10.8831 25.2598 11.2665 25.2863 11.65C25.2863 11.6963 25.2796 11.7492 25.3325 11.7822C25.3325 12.4103 25.3325 13.0385 25.3325 13.66C25.24 13.8914 25.2598 14.136 25.2201 14.3806C24.8829 16.7145 23.9904 18.8104 22.5027 20.6485C21.9341 21.3493 21.3127 21.9906 20.592 22.5328C20.4862 22.4535 20.387 22.3741 20.2812 22.2882C19.0118 21.2435 17.7424 20.2121 16.4729 19.1741Z"
                                    fill="#518EF7"
                                  />
                                  <path
                                    d="M16.4729 19.1744C17.7423 20.2125 19.0118 21.2439 20.2812 22.2819C20.3804 22.3679 20.4862 22.4472 20.592 22.5265C20.5721 22.553 20.5523 22.5794 20.5259 22.5993C18.7738 23.9745 16.7969 24.8406 14.5952 25.1844C14.2646 25.2373 13.9274 25.2241 13.6101 25.3299C12.9687 25.3299 12.3208 25.3299 11.6795 25.3299C11.3885 25.2241 11.0712 25.2373 10.767 25.1844C6.67443 24.4902 3.60662 22.3216 1.54378 18.7248C1.53056 18.705 1.52395 18.6785 1.51733 18.6587C2.87934 17.548 4.24134 16.4306 5.60334 15.3198C6.07277 16.4835 6.75377 17.4951 7.69923 18.3281C10.0728 20.4042 13.4977 20.7877 16.2679 19.2736C16.3407 19.2405 16.4068 19.2075 16.4729 19.1744Z"
                                    fill="#28B346"
                                  />
                                  <path
                                    d="M1.4314 6.86309C1.87438 5.93085 2.46282 5.09778 3.1372 4.33083C5.07442 2.14237 7.47445 0.727475 10.3571 0.218377C14.139 -0.4494 17.5572 0.416727 20.592 2.7837C20.7176 2.88288 20.7441 2.92916 20.5986 3.04817C19.3292 4.07297 18.0729 5.111 16.8101 6.14904C16.7043 6.23499 16.625 6.26804 16.4928 6.1887C12.4464 3.80851 7.23643 5.64655 5.59674 10.0896C5.58352 10.1293 5.56368 10.1623 5.54385 10.202C5.08764 9.83834 4.63805 9.46809 4.18185 9.10445C3.26283 8.35733 2.34381 7.61021 1.4314 6.86309Z"
                                    fill="#F04336"
                                  />
                                  <path
                                    d="M1.43139 6.86328C2.35041 7.6104 3.26282 8.35752 4.18184 9.09802C4.63143 9.46827 5.08764 9.83192 5.54384 10.1956C5.33227 10.9295 5.16698 11.67 5.14714 12.4435C5.12069 13.4353 5.2926 14.3873 5.61657 15.3196C4.25457 16.4304 2.89257 17.5477 1.53056 18.6585C0.876009 17.5147 0.452863 16.2783 0.214844 14.9824C-0.274419 12.2584 0.069387 9.64018 1.27271 7.14097C1.31899 7.0418 1.3785 6.95584 1.43139 6.86328Z"
                                    fill="#FABA01"
                                  />
                                </svg>
                              </span>
                              <span className="text-lg font-extrabold text-[#8D8D8D]">
                                Google
                              </span>
                            </p>
                          </div>
                          <div className="flex">
                            <div className="w-3/4">
                              <div className="py-4">
                                <p className="text-lg font-extrabold text-[#8D8D8D]">
                                  What I’ve learned
                                </p>
                                <ul className="">
                                  <li className="flex items-center space-x-2 space-y-1">
                                    <span className="">
                                      <img
                                        src="/assets/tick.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                    <span className="">
                                      Describe project management skills, roles,
                                      and responsibilities across a variety of
                                      industries
                                    </span>
                                  </li>
                                  <li className="flex items-center space-x-2 space-y-1">
                                    <span className="">
                                      <img
                                        src="/assets/tick.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                    <span className="">
                                      Explain the project management life cycle
                                      and compare different program management
                                      methodologies
                                    </span>
                                  </li>
                                  <li className="flex items-center space-x-2 space-y-1">
                                    <span className="">
                                      <img
                                        src="/assets/tick.svg"
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                    <span className="">
                                      Define organizational structure and
                                      organizational culture and explain how it
                                      impacts project management.
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="w-1/2">
                              <div className="py-4">
                                <p className="text-lg font-extrabold text-[#8D8D8D]">
                                  What I’ve learned
                                </p>
                                <div className="flex flex-wrap py-4">
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                                    Organizational Culture
                                  </p>
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                                    Career Development
                                  </p>
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                                    Strategic Thinking
                                  </p>
                                  <p className="bg-black text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                                    Change Management
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResumeSection;
