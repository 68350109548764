import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
const BrandSection = () => {
  const settings3 = {
    spaceBetween: 20,
    slidesPerView: 3,

    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    // loop: true,
    breakpoints: {
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1100: {
        slidesPerView: 6,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".slider4-next",
      prevEl: ".slider4-prev",
    },
  };
  return (
    <>
      <section className="brand__slider bg-[#002059]">
        <div className="mx-auto max-w-7xl px-4">
          <div className="py-5 pb-10">
            <div className="">
              <h3 className="mb-3 text-[34px] font-bold capitalize text-center leading-[28px] text-white pt-4 md:py-4">
                Brands I’ve Worked With
              </h3>
            </div>
            <div className="px-8 relative">
              <div className="w-full">
                <Swiper {...settings3} modules={[Autoplay, Navigation]}>
                  <SwiperSlide>
                    <img src="/assets/1.png" alt="" className="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/2.png" alt="" className="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/3.png" alt="" className="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/4.png" alt="" className="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/5.png" alt="" className="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/6.png" alt="" className="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/7.png" alt="" className="" />
                  </SwiperSlide>{" "}
                  <SwiperSlide>
                    <img src="/assets/8.png" alt="" className="" />
                  </SwiperSlide>{" "}
                  <SwiperSlide>
                    <img src="/assets/9.png" alt="" className="" />
                  </SwiperSlide>{" "}
                  <SwiperSlide>
                    <img src="/assets/10.png" alt="" className="" />
                  </SwiperSlide>
                </Swiper>
                <div>
                  <button
                    type="button"
                    // ref={navigationNextRef}
                    className="swiper-button-next slider4-next"
                  ></button>
                  <button
                    type="button"
                    // ref={navigationPrevRef}
                    className="swiper-button-prev slider4-prev"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default BrandSection;
