import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./App.css";
import HeroBanner from "./component/HeroBanner";
// Import Swiper styles
import "swiper/css";
import BrandSection from "./component/BrandSection";

import DigitalSlider from "./component/DigitalSlider";
import Footer from "./component/Footer";
import GameSlider from "./component/GameSlider";
import IntroSection from "./component/IntroSection";
import Navbar from "./component/Navbar";
import ResumeSection from "./component/ResumeSection";

function App() {
  const settings = {
    spaceBetween: 20,
    slidesPerView: 3,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: ".slider1-next",
      prevEl: ".slider1-prev",
    },
  };

  return (
    <div className="">
      <Navbar />
      <HeroBanner />
      <IntroSection />
      <section className="py-10">
        <div className="max-w-7xl mx-auto px-4">
          <h3 className="text-center text-[34px] font-extrabold text-[#8D8D8D]">
            My Expertise
          </h3>
          <div className="grid grid-cols-3 gap-x-8 pt-10">
            <div
              className="rounded-[22px] p-8 flex-col text-center"
              style={{ boxShadow: "0px 0px 84px -23px rgba(0, 0, 0, 0.15)" }}
            >
              <img
                src="/assets/planning.png"
                alt=""
                className="mx-auto w-[148px] h-[148px]"
              />
              <h3 className="text-[28px] font-bold py-2 leading-tight">
                Project Planning & Strategy
              </h3>
              <p className="text-lg text-[#8D8D8D] font-normal">
                I am responsible for defining project objectives, scope, and
                deliverables in collaboration with stakeholders. I develop
                project plans that outline the tasks, timelines, and resource
                requirements. I also establish project budgets and allocate
                resources effectively. Additionally, I create strategies for
                risk management, quality assurance, and communication to ensure
                the project's success.
              </p>
            </div>{" "}
            <div
              className="rounded-[22px] p-8 text-center"
              style={{ boxShadow: "0px 0px 84px -23px rgba(0, 0, 0, 0.15)" }}
            >
              <img
                src="/assets/team.png"
                alt=""
                className="mx-auto w-[148px] h-[148px]"
              />

              <h3 className="text-[28px] font-bold py-2 leading-tight">
                Team Coordination
              </h3>
              <p className="text-lg text-[#8D8D8D] font-normal">
                I facilitate collaboration among cross-functional teams, which
                may include designers, developers, marketers, and other
                specialists. I assemble project teams, assign roles and
                responsibilities, and ensure everyone is aligned with project
                goals. Effective communication and teamwork are crucial in
                digital projects, and I play a pivotal role in fostering a
                collaborative and productive work environment.
              </p>
            </div>{" "}
            <div
              className="rounded-[22px] p-8  text-center"
              style={{ boxShadow: "0px 0px 84px -23px rgba(0, 0, 0, 0.15)" }}
            >
              <img
                src="/assets/strategy 1.png"
                alt=""
                className="mx-auto w-[148px] h-[148px]"
              />
              <h3 className="text-[28px] font-bold py-2 leading-tight">
                Monitoring & Control
              </h3>
              <p className="text-lg text-[#8D8D8D] font-normal">
                I continuously monitor the project's progress against the
                established plans and objectives. I use project management tools
                and metrics to track key performance indicators (KPIs), budget
                expenditures, and timelines. When deviations or risks are
                identified, I take proactive measures to address them, such as
                adjusting the project plan, reallocating resources, or
                implementing mitigation strategies.
              </p>
            </div>
          </div>
        </div>
      </section>
      <BrandSection />
      <ResumeSection />

      <section className="pt-10" id="portfolio">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-black md:text-5xl lg:text-[50px]">
              Portfolio
            </h1>
            <p className="pb-8 text-[33px] capitalize font-medium text-[#8D8D8D] sm:px-16 xl:px-48 ">
              Projects I Have Worked With
            </p>
          </div>
        </div>
        <div
          className="py-5 "
          style={{ background: "url('/assets/protfolio-bg.png')" }}
        >
          <div className="mx-auto max-w-7xl px-4">
            <div className="py-10 border-b border-white development__slider">
              <div className="">
                <h3 className="mb-3 text-[34px] font-bold capitalize px-8 leading-[28px] text-white pt-4 md:py-4">
                  Web & Mobile App Development
                </h3>
              </div>
              <div className="px-8 relative">
                <div className="w-full">
                  <Swiper {...settings} modules={[Navigation]}>
                    <SwiperSlide>
                      <div className="w-full">
                        <img
                          src="/assets/app1.png"
                          alt=""
                          className="h-[260px] object-contain"
                        />
                        <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                          Robi Website AMP Integration
                        </h3>
                        <p className="flex items-center space-x-4 pb-2">
                          <span className="">
                            <img
                              src="/assets/cal-white.svg"
                              alt=""
                              className=""
                            />
                          </span>
                          <span className="text-white"> December 2020</span>
                        </p>
                        <p className="text-white text-justify text-base font-normal leading-tight">
                          Robi Axiata Limited is one of the leading Telco brands
                          in Bangladesh. The Robi website AMP integration was
                          one of the great revolutions in the history of the
                          Robi Axiata Limited. It has changed the entire web
                          browsing experience of the users. The term ‘AMP’
                          stands for ‘Accelerated Mobile Pages’ which makes it
                          easier to load web pages on mobile devices smoothly
                          and faster. AMP works by stripping pages down to their
                          most essential parts and storing a cached version on
                          Google servers, which allows them to deliver content
                          almost immediately.
                        </p>
                        <div className="py-3">
                          <p className="text-lg text-white font-extrabold pb-2">
                            My Role
                          </p>
                          <div className="flex flex-wrap ">
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Idiate
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Project Management
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Financial Plan
                            </p>{" "}
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Client Communication
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="w-full">
                        <img
                          src="/assets/app2.png"
                          alt=""
                          className="h-[260px] object-contain"
                        />
                        <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                          Banglalink BP tracker{" "}
                        </h3>
                        <p className="flex items-center space-x-4 pb-2">
                          <span className="">
                            <img
                              src="/assets/cal-white.svg"
                              alt=""
                              className=""
                            />
                          </span>
                          <span className="text-white"> March 2021</span>
                        </p>
                        <p className="text-white text-justify text-base font-normal leading-tight">
                          Banglalink is one of the leading telecommunications
                          operators in Bangladesh. It was launched in 2005 and
                          has since become a significant player in the country's
                          mobile communication industry. Banglalink offers a
                          range of services including voice calls, messaging,
                          data plans, and value-added services to both prepaid
                          and postpaid customers. The company has played a role
                          in expanding mobile connectivity across Bangladesh,
                          contributing to the country's digital transformation
                          allows them to deliver content almost immediately.
                        </p>
                        <div className="py-3">
                          <p className="text-lg text-white font-extrabold pb-2">
                            My Role
                          </p>
                          <div className="flex flex-wrap ">
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Project Planning
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Workflow Design{" "}
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Workflow Design
                            </p>{" "}
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Budget Planning
                            </p>{" "}
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Testing
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="w-full">
                        <img
                          src="/assets/app3.png"
                          alt=""
                          className="h-[260px] object-contain"
                        />
                        <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                          G2R Website Revamp
                        </h3>
                        <p className="flex items-center space-x-4 pb-2">
                          <span className="">
                            <img
                              src="/assets/cal-white.svg"
                              alt=""
                              className=""
                            />
                          </span>
                          <span className="text-white">August 2023</span>
                        </p>
                        <p className="text-white text-justify text-base font-normal leading-tight">
                          G2R is a digital agency based in Italy that offers
                          consulting and change management services to other
                          businesses. The agency is dedicated to helping
                          businesses navigate the ever-changing digital
                          landscape and find new opportunities for growth. We
                          got the opportunity to develop the G2R website. The
                          website is designed to showcase the agency's services
                          and expertise, and to provide a platform for potential
                          clients to connect with the G2R team. The G2R website
                          has a clean and modern design, with clear navigation
                          and easy-to-read contents.{" "}
                        </p>
                        <div className="py-3">
                          <p className="text-lg text-white font-extrabold pb-2">
                            My Role
                          </p>
                          <div className="flex flex-wrap ">
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Project Manager
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Business Process Outsourcing
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Team leading
                            </p>{" "}
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Financial Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="w-full">
                        <img
                          src="/assets/app4.png"
                          alt=""
                          className="h-[260px] object-contain"
                        />
                        <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                          Buy here Now Mobile App
                        </h3>
                        <p className="flex items-center space-x-4 pb-2">
                          <span className="">
                            <img
                              src="/assets/cal-white.svg"
                              alt=""
                              className=""
                            />
                          </span>
                          <span className="text-white">August 2021</span>
                        </p>
                        <p className="text-white text-justify text-base font-normal leading-tight">
                          BHN is an eCommerce and the app was built to sell the
                          products from the website. The app was built for the
                          Android & ISO platform. It was launched in mid-2018
                          with an aim to provide a 24/7 delivery service of
                          fresh snacks and beverage products. All available
                          features are the same on the website and the app. The
                          app could have the user log in, purchase products, and
                          make payments.
                        </p>
                        <div className="py-3">
                          <p className="text-lg text-white font-extrabold pb-2">
                            My Role
                          </p>
                          <div className="flex flex-wrap ">
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Project Manager
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Business Process Outsourcing
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Team leading
                            </p>{" "}
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Financial Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="w-full">
                        <img
                          src="/assets/app5.png"
                          alt=""
                          className="h-[260px] object-contain"
                        />
                        <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                          NWPGCL Career Portal
                        </h3>
                        <p className="flex items-center space-x-4 pb-2">
                          <span className="">
                            <img
                              src="/assets/cal-white.svg"
                              alt=""
                              className=""
                            />
                          </span>
                          <span className="text-white">August 2023</span>
                        </p>
                        <p className="text-white text-justify text-base font-normal leading-tight">
                          The NWPGCL Career Portal developed by Mavengers
                          Limited is an innovative solution designed to
                          streamline the recruitment process for the North-West
                          Power Generation Company Limited (NWPGCL). This system
                          provides an efficient and user-friendly platform for
                          job seekers to search and apply for job vacancies, and
                          for the company to manage the recruitment process.
                        </p>
                        <div className="py-3">
                          <p className="text-lg text-white font-extrabold pb-2">
                            My Role
                          </p>
                          <div className="flex flex-wrap ">
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Project Manager
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Business Process Outsourcing
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Team leading
                            </p>{" "}
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Financial Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <div className="w-full">
                        <img
                          src="/assets/app6.png"
                          alt=""
                          className="h-[260px] object-contain"
                        />
                        <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                          Ocean Life Website
                        </h3>
                        <p className="flex items-center space-x-4 pb-2">
                          <span className="">
                            <img
                              src="/assets/cal-white.svg"
                              alt=""
                              className=""
                            />
                          </span>
                          <span className="text-white">August 2022</span>
                        </p>
                        <p className="text-white text-justify text-base font-normal leading-tight">
                          Ocean Life is a full-fledged health equipment,
                          accessories, supplement and others similar product
                          importer and supplier. They established systems and
                          processes to ensure a seamless flow of work from
                          beginning to end. Ocean Life is well known for their
                          coronary stents which are used for patients with heart
                          blockage. To make the stents highly available for
                          hospitals during the surgery, Ocean LIfe decided to
                          design their own website. The main purpose of their
                          website was to provide company information, product
                          portfolio and contact details to their interested
                          clients (Hospitals).{" "}
                        </p>
                        <div className="py-3">
                          <p className="text-lg text-white font-extrabold pb-2">
                            My Role
                          </p>
                          <div className="flex flex-wrap ">
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Project Manager
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Business Process Outsourcing
                            </p>
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Team leading
                            </p>{" "}
                            <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                              Financial Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div>
                    <button
                      type="button"
                      // ref={navigationNextRef}
                      className="swiper-button-next slider1-next"
                    ></button>
                    <button
                      type="button"
                      // ref={navigationPrevRef}
                      className="swiper-button-prev slider1-prev"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-10 border-b border-white game__slider">
              <div className="">
                <h3 className="mb-3 px-8 text-[34px] font-bold capitalize leading-[28px] text-white pt-4 md:py-4">
                  Game Development
                </h3>
              </div>
              <GameSlider />
            </div>
            <div className="py-10 ">
              <div className="">
                <h3 className="mb-3 px-8 text-[34px] font-bold capitalize leading-[28px] text-white pt-4 md:py-4">
                  Digital marketing
                </h3>
              </div>
              <DigitalSlider />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default App;
