import React from "react";
import { motion } from "framer-motion";
const HeroBanner = () => {
  return (
    <>
      <section
        className="relative h-screen !bg-cover !bg-no-repeat !bg-center"
        style={{ background: "url('/assets/image 1.png')" }}
        id="banner"
      >
        {/* <img src="/assets/image 1.png" alt="" /> */}
        <div className="py-8 relative px-4 mx-auto max-w-7xl text-center h-full">
          <div className="h-full flex items-center justify-center w-full flex-col">
            <a
              href="#"
              className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-2 text-sm rounded-full "
              role="alert"
            >
              <img
                src="/assets/profile-img.png"
                alt=""
                className="w-[200px] rounded-full h-[200px] "
              />
            </a>
            <div className="pb-10">
              <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
                HI, I’M <br /> MD NAYEEM AL ISLAM BHUIYAN
              </h1>
              <p className="pb-8 text-4xl font-bold text-white lg:text-4xl sm:px-16 xl:px-48 ">
                Digital Project Manager
              </p>
            </div>

            <div className="absolute xs:bottom-10 bottom-5 w-full flex justify-center items-center">
              <a href="#about" className="flex items-center flex-col">
                <div className="w-[35px] h-[64px] rounded-3xl border-4 border-white flex justify-center items-start p-2">
                  <motion.div
                    animate={{
                      y: [0, 24, 0],
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      repeatType: "loop",
                    }}
                    className="w-3 h-3 rounded-full bg-white mb-1"
                  />
                </div>
                <span className="text-white font-medium text-[22px] uppercase mt-4">
                  Scroll Down To Explore More
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroBanner;
