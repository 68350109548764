import React from "react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
const DigitalSlider = () => {
  const settings = {
    spaceBetween: 20,
    slidesPerView: 3,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".slider3-next",
      prevEl: ".slider3-prev",
    },
  };
  return (
    <>
      <div className="px-8 relative digital__slider">
        <div className="w-full">
          <Swiper {...settings} modules={[Navigation]}>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/digi1.png"
                  alt=""
                  className="h-[260px] object-contain mx-auto"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  NWPGCL Content Management
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">December 2017</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  NWPGCL Stands for North West Power Generation Company Limited,
                  which is a government Power plant of Bangladesh. In order to
                  maintain sustainability and stakeholder’s interest they are
                  highly active on social media and publish many informative
                  contents. Since my company was nominated to become the Social
                  Media Content Agency, I have created interactive contents that
                  meet the client’s expectations and deliver the projected
                  information to all stakeholders. The contents were highly
                  appreciated both social media and print on demand format.{" "}
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Idiate{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Content Planning{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Maintain Media Team{" "}
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication{" "}
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/digi2.png"
                  alt=""
                  className="h-[260px] object-contain mx-auto"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  DBBL Rocket FB Page Management
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">March 2018</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  Rocket is the mobile financing service of Country’s leading
                  private bank Dutch-Bangla Bank limited. Rocket is the pioneer
                  in mobile banking and electronic money transaction. The
                  primary objective of Rocket’s Facebook page is to educate
                  people specially living in rural areas to use the service for
                  money transfer and making transactions securely. I have made
                  such wonderful communication material in Bangla for making it
                  easily understandable among all classes of people. I have also
                  teamed up with Communication team to delivery 24/7 reply
                  service.{" "}
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Content Planning
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Budget Planning
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Content Scheduling
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/digi3.png"
                  alt=""
                  className="h-[260px] object-contain mx-auto"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Make a wish FB Page Management
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">August 2019</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  Make A Wish is a leading travel agency in Bangladeshi with the
                  facility to book tickets, hotels and tour-packages online with
                  the huge global networks. The primary objective of Make A
                  wish’s Facebook Page is to offer exciting holiday packages to
                  people and making their tour easy by providing all sort of
                  service under one platform. people can compare the price of
                  air tickets and hotels as they plan for any holiday or
                  business trip and get the best option. I have worked with both
                  my internal and their team to spread the facility to all
                  classes of people and create awareness about the service.
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Content Planning{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Team Management
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Budget Planning
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Page Quality Maintenance
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/digi4.png"
                  alt=""
                  className="h-[260px] object-contain mx-auto"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Mavengers FB Page management
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">August 2016</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  Mavengers Limited's Facebook page management involves the
                  planning, creation, and execution of content and strategies to
                  effectively engage with its audience and achieve its marketing
                  and communication goals. The specific strategies and tactics
                  used to manage Mavengers Limited's Facebook page may evolve
                  over time based on changing business goals, audience
                  preferences, and trends in social media marketing. Effective
                  Facebook page management is an ongoing process that requires
                  adaptability and a deep understanding of the target audience
                  and industry.
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Idiate{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Content Planning
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Maintain Media Team
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/digi5.png"
                  alt=""
                  className="h-[260px] object-contain mx-auto"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  D Akbar FB Page management
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">March 2015</span>
                </p>
                <p className="text-white text-justify text-lg font-normal leading-tight">
                  Managing a Facebook page for an individual, such as D. Akbar,
                  involves a tailored approach to suit their personal brand,
                  goals, and audience. t's important to note that managing a
                  Facebook page for an individual, especially a public figure,
                  involves careful consideration of their personal brand and
                  reputation. The content shared should reflect their values,
                  goals, and image. Additionally, social media management for
                  individuals often requires a balance between personal and
                  professional content, depending on the individual's
                  preferences and audience expectations.
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Idiate{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Content Planning
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Maintain Media Team
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/digi6.png"
                  alt=""
                  className="h-[260px] object-contain mx-auto"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Ocean Life FB Page management
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">December 2014</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  Managing a Facebook page dedicated to ocean life involves
                  curating and sharing content that educates, entertains, and
                  raises awareness about marine ecosystems and creatures. The
                  goal is to engage and inspire an audience passionate about
                  marine life. Effective Facebook page management for an "Ocean
                  Life" page involves a deep passion for marine conservation and
                  a commitment to sharing valuable and inspiring content that
                  educates and engages the audience while advocating for the
                  preservation of our oceans and marine ecosystems
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Idiate{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Content Planning
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Maintain Media Team
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div>
            <button
              type="button"
              // ref={navigationNextRef}
              className="swiper-button-next slider3-next"
            ></button>
            <button
              type="button"
              // ref={navigationPrevRef}
              className="swiper-button-prev slider3-prev"
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalSlider;
