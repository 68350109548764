import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="py-10 pb-5" id="contact">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-black md:text-5xl lg:text-[50px]">
              Let’s Get Connected{" "}
            </h1>
            <p className="pb-8 text-[33px] capitalize font-medium text-[#8D8D8D] sm:px-16 xl:px-48 ">
              Feel free to share your queries{" "}
            </p>
          </div>
          <div className="max-w-4xl mx-auto py-10">
            <div className="grid grid-cols-2 gap-x-10">
              <div className="">
                <h2 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-[#002059] md:text-5xl lg:text-[40px]">
                  MD NAYEEM AL ISLAM
                </h2>
                <ul className="text-[#002059]">
                  <li className="flex items-center text-lg font-normal space-x-4 mb-2">
                    <span className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="26"
                        viewBox="0 0 25 26"
                        fill="none"
                      >
                        <path
                          d="M24.9845 21.4323C24.8221 22.1707 24.4191 22.8322 23.8405 23.3105C23.1684 24.0054 22.3566 24.5459 21.4603 24.8957C20.6762 25.2038 19.8424 25.3618 19.0014 25.3618H18.5806C16.6904 25.259 14.8394 24.7744 13.1366 23.9366H13.1236C12.729 23.7501 12.3214 23.5637 11.9268 23.3372C9.94015 22.2066 8.11707 20.8036 6.50917 19.1679C4.42393 17.1844 2.71288 14.8328 1.45973 12.2281C0.794521 10.8005 0.350979 9.27725 0.144768 7.71245C-0.161973 6.07493 0.0212098 4.38192 0.670782 2.85056C1.08037 2.1596 1.58019 1.52784 2.15671 0.972404C2.41001 0.681969 2.71855 0.446385 3.06396 0.279761C3.42676 0.107407 3.8206 0.0121687 4.22114 0C4.64677 0.0483862 5.05874 0.181599 5.43337 0.391915C5.80799 0.60223 6.13792 0.885519 6.40402 1.22548C6.99576 1.87818 7.71893 2.55752 8.31067 3.18357L9.1261 3.99609C9.60544 4.42649 9.90207 5.02777 9.95438 5.67445C9.95455 6.26864 9.73935 6.8422 9.34954 7.28619C9.09713 7.59714 8.82943 7.89502 8.54743 8.17868L8.28451 8.45838C8.13152 8.60167 8.01432 8.77974 7.9426 8.9779C7.87792 9.17057 7.85104 9.37418 7.86362 9.57729C8.07323 10.143 8.40061 10.6564 8.82353 11.0825C9.48101 11.9882 10.1385 12.7742 10.8092 13.6267C12.0226 15.0529 13.4625 16.2639 15.0697 17.2099C15.2329 17.3318 15.422 17.4139 15.6219 17.4496C15.7967 17.4622 15.9719 17.4349 16.1348 17.3697C16.5942 17.0966 17.0036 16.7452 17.3446 16.3307C17.8045 15.7609 18.4641 15.3934 19.1855 15.305C19.8792 15.3151 20.5409 15.6022 21.0265 16.1042C21.2894 16.3307 21.5787 16.6371 21.8548 16.9302L22.2494 17.3431L22.657 17.7426L23.354 18.4619C23.77 18.8347 24.161 19.2353 24.5243 19.6608C24.8896 20.173 25.0536 20.8043 24.9845 21.4323Z"
                          fill="#002059"
                        />
                      </svg>
                    </span>
                    <span className="">+88 01911545945</span>
                  </li>{" "}
                  <li className=" mb-2">
                    <a
                      href="mailto:nayembd1990@gmail.com"
                      className="flex items-center text-lg font-normal space-x-4"
                    >
                      <span className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={26}
                          height={23}
                          viewBox="0 0 26 23"
                          fill="none"
                        >
                          <path
                            d="M22.022 0.0800781H3.978C2.92297 0.0800781 1.91113 0.486818 1.16511 1.21082C0.419094 1.93484 0 2.91679 0 3.9407V18.5252C0 19.5503 0.418665 20.5335 1.16432 21.2596C1.90997 21.9855 2.92178 22.3952 3.978 22.3985H22.022C23.0782 22.3952 24.09 21.9855 24.8357 21.2596C25.5813 20.5335 26 19.5503 26 18.5252V3.9407C25.9966 2.91781 25.5763 1.93779 24.831 1.21451C24.0857 0.491221 23.076 0.0834089 22.022 0.0800781ZM13 10.1732L2.05403 3.37296C2.18959 2.98131 2.44789 2.64063 2.79289 2.39857C3.13789 2.1565 3.55229 2.02519 3.978 2.02302H22.022C22.4801 2.02532 22.9241 2.17714 23.2825 2.45404C23.6409 2.73095 23.893 3.11683 23.998 3.54959L13 10.1732Z"
                            fill="#002059"
                          />
                        </svg>
                      </span>
                      <span className="">nayembd1990@gmail.com</span>
                    </a>
                  </li>
                  <li className="flex items-center text-lg font-normal space-x-4 mb-2">
                    <span className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={27}
                        viewBox="0 0 26 27"
                        fill="none"
                      >
                        <path
                          d="M8.34603 25.3999C6.05969 24.5266 4.06312 23.0156 2.58649 21.0412C1.10986 19.0668 0.21325 16.7092 0 14.2402H5.54342C5.52238 18.1419 6.48728 21.9841 8.34603 25.3999Z"
                          fill="#002059"
                        />
                        <path
                          d="M17.976 14.2402C17.7415 21.3544 15.1858 26.2266 12.877 26.2266C10.5683 26.2266 8.01266 21.3544 7.77808 14.2402H17.976Z"
                          fill="#002059"
                        />
                        <path
                          d="M25.7295 14.2402C25.5224 16.7129 24.6266 19.0749 23.1468 21.0507C21.6669 23.0266 19.6639 24.535 17.3711 25.3999C19.2399 21.9875 20.2095 18.1439 20.1861 14.2402H25.7295Z"
                          fill="#002059"
                        />
                        <path
                          d="M25.7295 11.9858H20.1861C20.2128 8.0818 19.243 4.23728 17.3711 0.826172C19.6639 1.69115 21.6669 3.19949 23.1468 5.17536C24.6266 7.15123 25.5224 9.51318 25.7295 11.9858Z"
                          fill="#002059"
                        />
                        <path
                          d="M17.976 11.9862H7.77808C8.01266 4.85962 10.5683 0 12.877 0C15.1858 0 17.7292 4.85962 17.976 11.9862Z"
                          fill="#002059"
                        />
                        <path
                          d="M8.34603 0.889648C6.48418 4.30426 5.51907 8.14719 5.54342 12.0493H0C0.208938 9.57902 1.1039 7.21943 2.5811 5.24422C4.05831 3.26902 6.05725 1.75912 8.34603 0.889648Z"
                          fill="#002059"
                        />
                      </svg>
                    </span>
                    <a href="https://www.nayeemalislam.com/" className="">
                      nayeemalislam.com
                    </a>
                  </li>{" "}
                </ul>
              </div>
              <div className="">
                <div className="pb-5">
                  <p className="text-lg font-extrabold mb-4 text-center">
                    Home Address
                  </p>
                  <ul className="">
                    <li className="text-[#002059] flex items-center  space-x-4">
                      <span className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={37}
                          height={35}
                          viewBox="0 0 37 35"
                          fill="none"
                        >
                          <path
                            d="M2 17.741L17.1529 2.55823C17.8975 1.81392 19.1025 1.81392 19.8454 2.55823L35 17.741M5.80769 13.9262V31.0926C5.80769 32.1455 6.66062 33 7.71154 33H14.6923V24.7347C14.6923 23.6818 15.5452 22.8273 16.5962 22.8273H20.4038C21.4548 22.8273 22.3077 23.6818 22.3077 24.7347V33H29.2885C30.3394 33 31.1923 32.1455 31.1923 31.0926V13.9262M12.1538 33H26.1154"
                            stroke="#002059"
                            strokeWidth={3}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span className="">
                        House 84 (Razbari Compound), Cumilla City Corporation,
                        Cumilla 3500, Bangladesh.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <p className="text-lg font-extrabold mb-4 text-center">
                    Office Address
                  </p>
                  <ul className="">
                    <li className="text-[#002059] flex items-center space-x-4">
                      <span className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={37}
                          height={35}
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_23_11)">
                            <path
                              d="M7.19702 37.0064C8.01974 37.0064 8.84246 37.0064 9.70262 37.0064C9.70262 36.8202 9.70262 36.6675 9.70262 36.5149C9.70262 28.7062 9.70262 20.8976 9.70262 13.0899C9.70262 12.0973 9.93206 11.8016 10.8863 11.5453C15.6076 10.2752 20.3346 9.02527 25.0453 7.71391C26.0677 7.42975 26.8751 8.04415 26.8261 9.08671C26.7657 10.375 26.8117 11.6691 26.8117 12.9853C26.0265 12.9853 25.2652 12.9853 24.4665 12.9853C24.4665 12.128 24.4665 11.2602 24.4665 10.3395C23.9404 10.4768 23.4613 10.5987 22.9842 10.7274C19.4514 11.6778 15.9205 12.632 12.3858 13.5738C12.1295 13.6419 12.0546 13.7494 12.0546 14.0106C12.0623 21.5552 12.0604 29.1008 12.0604 36.6454C12.0604 36.751 12.0604 36.8576 12.0604 36.9872C16.1893 36.9872 20.2914 36.9872 24.4473 36.9872C24.4473 36.8422 24.4473 36.6925 24.4473 36.5427C24.4473 29.9677 24.4473 23.3926 24.4473 16.8186C24.4473 15.8038 24.8735 15.3776 25.8863 15.3766C30.6527 15.3738 35.4191 15.3699 40.1855 15.368C41.0092 15.368 41.4815 15.8317 41.4825 16.6544C41.4844 21.2288 41.4834 25.8042 41.4825 30.3786C41.4825 30.4842 41.4719 30.5888 41.4661 30.7136C40.6885 30.7136 39.9301 30.7136 39.1324 30.7136C39.1324 26.3859 39.1324 22.0813 39.1324 17.7498C35.0159 17.7498 30.9388 17.7498 26.8367 17.7498C26.8367 24.1606 26.8367 30.561 26.8367 37.0026C32.5381 37.0026 38.2425 37.0026 43.9679 37.0026C43.9679 37.8042 43.9679 38.5654 43.9679 39.3776C31.7125 39.3776 19.4543 39.3776 7.19702 39.3776C7.19702 38.5875 7.19702 37.7974 7.19702 37.0064Z"
                              fill="#002059"
                            />
                            <path
                              d="M21.0537 20.7998C21.0537 21.588 21.0537 22.3387 21.0537 23.1153C19.198 23.1153 17.3529 23.1153 15.478 23.1153C15.478 22.356 15.478 21.5889 15.478 20.7998C17.3299 20.7998 19.175 20.7998 21.0537 20.7998Z"
                              fill="#002059"
                            />
                            <path
                              d="M35.7169 23.1297C33.8468 23.1297 32.0094 23.1297 30.147 23.1297C30.147 22.3531 30.147 21.5851 30.147 20.7979C32.0055 20.7979 33.8507 20.7979 35.7169 20.7979C35.7169 21.5764 35.7169 22.3348 35.7169 23.1297Z"
                              fill="#002059"
                            />
                            <path
                              d="M21.0345 28.5047C19.1721 28.5047 17.3424 28.5047 15.48 28.5047C15.48 27.7271 15.48 26.9591 15.48 26.1719C17.3241 26.1719 19.1645 26.1719 21.0345 26.1719C21.0345 26.9341 21.0345 27.7031 21.0345 28.5047Z"
                              fill="#002059"
                            />
                            <path
                              d="M35.7139 28.498C33.8439 28.498 32.0045 28.498 30.1431 28.498C30.1431 27.7214 30.1431 26.9611 30.1431 26.1768C31.9939 26.1768 33.8419 26.1768 35.7139 26.1768C35.7139 26.9419 35.7139 27.7022 35.7139 28.498Z"
                              fill="#002059"
                            />
                            <path
                              d="M15.4646 17.7446C15.4646 16.9747 15.4646 16.2268 15.4646 15.4502C17.3203 15.4502 19.1654 15.4502 21.0374 15.4502C21.0374 16.2009 21.0374 16.9593 21.0374 17.7446C19.1942 17.7446 17.3491 17.7446 15.4646 17.7446Z"
                              fill="#002059"
                            />
                            <path
                              d="M15.478 31.5361C17.3452 31.5361 19.1827 31.5361 21.0422 31.5361C21.0422 32.3099 21.0422 33.0683 21.0422 33.8488C19.1846 33.8488 17.3404 33.8488 15.478 33.8488C15.478 33.0712 15.478 32.3051 15.478 31.5361Z"
                              fill="#002059"
                            />
                            <path
                              d="M35.7072 33.8556C33.84 33.8556 32.0026 33.8556 30.1431 33.8556C30.1431 33.0818 30.1431 32.3234 30.1431 31.543C31.9997 31.543 33.8448 31.543 35.7072 31.543C35.7072 32.3196 35.7072 33.0857 35.7072 33.8556Z"
                              fill="#002059"
                            />
                            <path
                              d="M39.1555 32.8613C39.9283 32.8613 40.6781 32.8613 41.4557 32.8613C41.4557 33.5813 41.4557 34.2908 41.4557 35.029C40.704 35.029 39.9446 35.029 39.1555 35.029C39.1555 34.3244 39.1555 33.6149 39.1555 32.8613Z"
                              fill="#002059"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_23_11">
                              <rect width={48} height={48} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className="">
                        House 20 (Level 3), Road 10, Nikunja 2, Dhaka 1229,
                        Bangladesh.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-[#D9D9D9] pt-5">
          <div className="max-w-7xl mx-auto px-4">
            <p className="text-center text-base font-normal text-[#002059]">
              © 2023 MD Nayeem Al Islam Bhuiyan. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
