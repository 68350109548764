function IntroSection() {
  return (
    <>
      <section className="py-10" id="about">
        <div className="max-w-7xl mx-auto px-4">
          <div className="">
            <div className="text-center">
              {" "}
              <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-black md:text-5xl lg:text-[50px]">
                MD NAYEEM AL ISLAM BHUIYAN
              </h1>
              <p className="pb-8 text-[33px] capitalize font-medium text-[#8D8D8D] sm:px-16 xl:px-48 ">
                Digital Project Manager
              </p>
            </div>
            <div className="">
              <h3 className="text-center pb-5 text-[34px] capitalize font-bold text-[#8D8D8D] sm:px-16 xl:px-48 ">
                Introduction
              </h3>
              <div className="">
                <p className="text-[#8D8D8D] text-lg font-normal">
                  {" "}
                  Hi my name is Nayeem Al Islam Bhuiyan. I'm a 33 year old
                  Digital Project Manager from Dhaka Bangladesh. Having 10 years
                  experience in interactive agencies as project manager on major
                  international accounts, mainly in the lT & Digital Project
                  sector. I enjoy working closely with colleagues of all
                  disciplines - from Developers to Creative Directors and
                  clients of varying digital experience.If you want to know more
                  about me just further explore this page.Thanks a lot for
                  visiting.
                </p>
                <p className="text-[#8D8D8D] text-lg font-normal pt-5 pb-2">
                  {" "}
                  Kind Regards,
                </p>
                <img src="/assets/signature.png" alt="" className="" />
              </div>
            </div>
          </div>
          <div className="pt-10 flex flex-col space-y-10">
            <h3 className="text-center text-[34px] font-extrabold pb-5 text-[#8D8D8D]">
              About me
            </h3>

            <div className="flex space-x-12 w-full px-8">
              <p className="text-lg font-extrabold uppercase w-1/12">Skills</p>
              <div className="flex w-11/12 space-x-8">
                <div className="flex-1">
                  <div className="grid grid-cols-3 gap-x-4">
                    <div className="flex items-center flex-col px-2">
                      <img src="/assets/skill1.png" alt="" className="" />
                      <p className="text-base font-normal text-center text-[#090606]">
                        People Management
                      </p>
                    </div>
                    <div className="flex items-center flex-col px-2">
                      <img src="/assets/skill2.png" alt="" className="" />
                      <p className="text-base font-normal text-center text-[#090606]">
                        Project Management Tools
                      </p>
                    </div>
                    <div className="flex items-center flex-col px-2">
                      <img src="/assets/skill3.png" alt="" className="" />
                      <p className="text-base font-normal text-center text-[#090606]">
                        Agile Project Management
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="grid grid-cols-3 gap-x-8">
                    <div className="flex items-center flex-col px-2">
                      <img src="/assets/skill4.png" alt="" className="" />
                      <p className="text-base font-normal text-center text-[#090606]">
                        CRM
                      </p>
                    </div>
                    <div className="flex items-center flex-col px-2">
                      <img src="/assets/skill5.png" alt="" className="" />
                      <p className="text-base font-normal text-center text-[#090606]">
                        Digital Marketing Tools
                      </p>
                    </div>
                    <div className="flex items-center flex-col px-2">
                      <img src="/assets/skill6.png" alt="" className="" />
                      <p className="text-base font-normal text-center text-[#090606]">
                        Microsoft Office Suite
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-32 w-full px-8">
              <div className="flex flex-1 space-x-8">
                <p className="text-lg font-extrabold uppercase w-3/12">
                  What I Do
                </p>

                <p className="w-9/12">
                  I define project objectives and develop strategies to achieve
                  them. I coordinate diverse teams, assigning roles and
                  fostering collaboration. I continuously monitor any project
                  from start to finish.
                </p>
              </div>{" "}
              <div className="flex-1">
                <div className="flex-1 flex items-center space-x-8">
                  <p className="text-lg font-extrabold uppercase">
                    Social profile
                  </p>
                  <div className="flex items-center space-x-2">
                    <a href="https://www.facebook.com/nayem.cm" className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                      >
                        <circle
                          cx="20.9142"
                          cy="20.9142"
                          r="17.4286"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M25.5459 14.7776C25.0027 14.6689 24.2691 14.5878 23.8077 14.5878C22.5584 14.5878 22.4772 15.1309 22.4772 16.0001V17.5472H25.6003L25.328 20.752H22.4772V30.5H18.5669V20.752H16.5571V17.5472H18.5669V15.5649C18.5669 12.8495 19.8431 11.3286 23.0473 11.3286C24.1605 11.3286 24.9753 11.4916 26.0342 11.7088L25.5459 14.7776Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://www.instagram.com/inayem1990/"
                      className=""
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width="36"
                        height="36"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="flex-1 pt-5 flex space-x-8">
                  <p className="text-lg font-extrabold uppercase">Email </p>
                  <a href="mailto:nayembd1990@gmail.com" className="">
                    nayembd1990@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default IntroSection;
