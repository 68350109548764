import React from "react";

const Navbar = () => {
  return (
    <>
      <nav
        className=" fixed w-full z-20 top-0 left-0"
        style={{
          border: "1px solid rgba(97, 88, 141, 0.52)",
          background: "rgba(97, 88, 141, 0.84)",
          backdropFilter: "blur(8.199999809265137px)",
        }}
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="/" className="flex items-center">
            <img
              src="/assets/Nayeem Al Islam.png"
              className="h-8 mr-3"
              alt="Nayeem Al Islam"
            />
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg  md:flex-row md:space-x-8 md:mt-0">
              <li>
                <a
                  href="#banner"
                  className="block py-2 pl-3 pr-4 text-white text-lg rounded md:bg-transparent md:p-0"
                  aria-current="page"
                >
                  Welcome
                </a>
              </li>
              <li>
                <a
                  href="#resume"
                  className="block py-2 pl-3 pr-4 text-white text-lg rounded md:bg-transparent md:p-0"
                  aria-current="page"
                >
                  Résumé
                </a>
              </li>
              <li>
                <a
                  href="#portfolio"
                  className="block py-2 pl-3 pr-4 text-white text-lg rounded md:bg-transparent md:p-0"
                  aria-current="page"
                >
                  Portfolio
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="block py-2 pl-3 pr-4 text-white text-lg rounded md:bg-transparent md:p-0"
                  aria-current="page"
                >
                  Contacts
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
