import React from "react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
const GameSlider = () => {
  const settings = {
    spaceBetween: 20,
    slidesPerView: 3,
    wrapper: ".swiper-wrapper2",
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".slider2-next",
      prevEl: ".slider2-prev",
    },
  };
  return (
    <>
      <div className="px-8 relative game__slider">
        <div className="w-full">
          <Swiper {...settings} modules={[Navigation]}>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/game1.png"
                  alt=""
                  className="h-[260px] object-contain"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Tik Tac toe Glow{" "}
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">March 2022</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  Tic Tac Toe Glow" retains the fundamental rules of the
                  traditional Tic Tac Toe game, where two players take turns
                  marking spaces on a 3x3 grid with their respective symbols (X
                  and O) to achieve a winning combination.The game is known for
                  its vibrant and glowing interface, which adds a visually
                  appealing touch to the traditional gameplay.{" "}
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Game Overview Design
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Project Management{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Publishing
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Financial Plan
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/game2.png"
                  alt=""
                  className="h-[260px] object-contain"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Air Hockey
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">December 2021</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  Air Hockey" is a classic and highly popular Android game that
                  brings the fast-paced action of the traditional air hockey
                  arcade game to the palm of your hand. Developed by Mavengers
                  Limited in different versions, these games offer an exciting
                  and enjoyable gaming experience. Air hockey is a two-player
                  game where each player controls a paddle and tries to score
                  goals by hitting a puck into the opponent's goal.
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Game Overview Design
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Project Management{" "}
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Publishing
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Financial Plan
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/game3.png"
                  alt=""
                  className="h-[260px] object-contain"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Bubble Shooter
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">August 2020</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  Bubble Shooter is a popular and addictive Android game that
                  falls under the category of casual puzzle games. Developed by
                  various studios, Bubble Shooter has gained a massive following
                  due to its straightforward yet challenging gameplay. Bubble
                  Shooter is a matching game where players shoot collared
                  bubbles from the bottom of the screen to form groups of three
                  or more bubbles of the same color. When these groups are
                  created, the bubbles pop and disappear.{" "}
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Game Overview Design
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Project Management
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Client Communication
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Publishing
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Financial Plan
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/game4.png"
                  alt=""
                  className="h-[260px] object-contain"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Hex Puzzle
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">December 2023</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  A Hex Puzzle Game is a captivating and challenging puzzle game
                  that revolves around hexagonal tiles. In this type of game,
                  players are presented with a grid or board made up of
                  hexagons, and their objective is to manipulate and arrange
                  these hexagonal tiles to solve puzzles or achieve specific
                  goals. Hex Puzzle Games promote critical thinking, spatial
                  reasoning, and pattern recognition skills. They can also
                  enhance problem-solving abilities and cognitive flexibility.
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Project Management
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Business Process Outsourcing
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Team leading
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Financial Plan
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/game5.png"
                  alt=""
                  className="h-[260px] object-contain"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  Spot It Find The Difference
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">Saptember 2020</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  "Spot it" is an engaging and entertaining pattern recognition
                  game that's perfect for kids and the entire family! This
                  award-winning game challenges players to spot five subtle
                  differences between two images that appear otherwise similar.
                  It's not just a game; it's an exciting puzzle that encourages
                  players to sharpen their focus, enhance their visual
                  perception skills, and refine their fine motor abilities. As
                  players delve into the world of "Spot it," they embark on a
                  captivating journey of discovery.
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Project Management
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Business Process Outsourcing
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Team leading
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Financial Plan
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full">
                <img
                  src="/assets/game6.png"
                  alt=""
                  className="h-[260px] object-contain"
                />
                <h3 className="text-2xl text-white font-extrabold capitalize pb-4">
                  First World Cyber War{" "}
                </h3>
                <p className="flex items-center space-x-4 pb-2">
                  <span className="">
                    <img src="/assets/cal-white.svg" alt="" className="" />
                  </span>
                  <span className="text-white">August 2022</span>
                </p>
                <p className="text-white text-justify text-base font-normal leading-tight">
                  It is a strategy game high-end cyber combat. Explore research
                  programs. Growth your tactical hacker reputation and dominate
                  world. It places players in the shoes of leaders and
                  commanders of various nations as they navigate the
                  complexities of a fictional, large-scale cyber conflict in a
                  near-future setting. Multiplayer options allow players to
                  engage in cyber warfare against human opponents, forming
                  alliances, and competing for dominance in the virtual world.
                </p>
                <div className="py-3">
                  <p className="text-lg text-white font-extrabold pb-2">
                    My Role
                  </p>
                  <div className="flex flex-wrap ">
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Project Management
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Business Process Outsourcing
                    </p>
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Team leading
                    </p>{" "}
                    <p className="bg-white text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded mb-2">
                      Financial Plan
                    </p>{" "}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div>
            <button
              type="button"
              // ref={navigationNextRef}
              className="swiper-button-next slider2-next"
            ></button>
            <button
              type="button"
              // ref={navigationPrevRef}
              className="swiper-button-prev slider2-prev"
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameSlider;
